import React from "react";
import logo from '../images/mad-arai-d-B-optimized.png'

export function Footer() {
  return (
    <div className='page-footer'>
      <div className='wrapper'>
        <div className='footer-content'>
          <div className='info'>
            <img className='my-8 w-24' src={logo} alt='Mad Arai D Logo'/>
            <div className='mt-8'>
              <h3>address</h3>
              <p>
                109 Phahon Yothon Soi Prachankadee,
                <br/>
                Phayathai, Bangkok 10400 </p>
            </div>

            <div className='mt-8'>
              <h3>contact</h3>
              <p>
                <a href='tel:+123456789'>+ 66.2279.22779</a>
              </p>
              <p className='hours'>(Mon - Fri at 9:30 AM to 18:30 PM)</p>
              <p>
                <a href='mailto:mad@madaraid.com'>mad@madaraid.com</a>
              </p>
            </div>
          </div>

          <div className='signup mt-8'>
            <h3>sign up for news</h3>
            <p>Be the first to hear our latest projects, career opportunities and more</p>

            <div className='mt-8'>
              <input type='text' placeholder='Full name'/>
              <input type='email' placeholder='Email address'/>
              <button>Submit</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}