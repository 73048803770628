import hash from 'object-hash';
import Kaspy from './images/project-thumbnails/Project01.jpg';
import Pros from './images/project-thumbnails/Project02.jpg';
import Kaan from './images/project-thumbnails/Project03.jpg';
import Toyota from './images/project-thumbnails/Project04.jpg';
import Panjaluck from './images/project-thumbnails/Project05.jpg';
import Ramada from './images/project-thumbnails/Project06.jpg';
import Manit from './images/project-thumbnails/Project07.jpg';
import Bodyslam from './images/project-thumbnails/Project08.jpg';
import BMMF from './images/project-thumbnails/Project09.jpg';

import pros1 from './images/projects/pros/Pross01.jpg';
import pros5 from './images/projects/pros/Pross02.jpg';
import pros6 from './images/projects/pros/Pross03.jpg';
import pros2 from './images/projects/pros/Mobile_Scroll-1.jpg';
import pros3 from './images/projects/pros/Mobile_Scroll-2.jpg';
import pros4 from './images/projects/pros/Mobile_Scroll-3.jpg';

import kaan1 from './images/projects/kaan/KAAN01.png';
import kaan5 from './images/projects/kaan/KAAN02.png';
import kaan6 from './images/projects/kaan/KAAN03.png';
import kaan2 from './images/projects/kaan/KAAN04.png';
import kaan3 from './images/projects/kaan/KAAN05.png';
import kaan4 from './images/projects/kaan/KAAN06.png';

import ramada1 from './images/projects/ramada/RAMADA01.jpg';
import ramada5 from './images/projects/ramada/RAMADA02.jpg';
import ramada6 from './images/projects/ramada/RAMADA03.jpg';
import ramada2 from './images/projects/ramada/RAMADA04.jpg';
import ramada3 from './images/projects/ramada/RAMADA05.jpg';
import ramada4 from './images/projects/ramada/RAMADA06.jpg';

import bmmf1 from './images/projects/bmmf/BMMF01.jpg';
import bmmf2 from './images/projects/bmmf/BMMF02.png';
import bmmf3 from './images/projects/bmmf/BMMF03.png';

import bodyslam1 from './images/projects/bodyslam/BS01.jpg';
import bodyslam5 from './images/projects/bodyslam/BS02.jpg';
import bodyslam6 from './images/projects/bodyslam/BS03.jpg';
import bodyslam2 from './images/projects/bodyslam/BS04.jpg';
import bodyslam3 from './images/projects/bodyslam/BS05.jpg';
import bodyslam4 from './images/projects/bodyslam/BS06.jpg';

import panjaluck1 from './images/projects/panjaluck/PJL01.png';
import panjaluck4 from './images/projects/panjaluck/PJL02.png';
import panjaluck5 from './images/projects/panjaluck/PJL03.png';
import panjaluck2 from './images/projects/panjaluck/PJL04.png';
import panjaluck3 from './images/projects/panjaluck/PJL05.png';

import toyota1 from './images/projects/toyota/TOYOTA01.png';
import toyota4 from './images/projects/toyota/TOYOTA02.png';
import toyota5 from './images/projects/toyota/TOYOTA03.png';
import toyota2 from './images/projects/toyota/TOYOTA04.png';
import toyota3 from './images/projects/toyota/TOYOTA05.png';

import manit1 from './images/projects/manit/Manit01.png';
import manit4 from './images/projects/manit/Manit02.png';
import manit5 from './images/projects/manit/Manit03.png';
import manit2 from './images/projects/manit/Manit04.png';
import manit3 from './images/projects/manit/Manit05.png';

export type Project =
  BaseProject & {
    route: string;
    key: string
  };

export type BaseProject = {
  name: string;
  description: string;
  image: string;
  images: string[];
  capabilities: string[];
  year: number;
  template: 'grid1' | 'grid2' | 'grid3' | 'grid4' | 'grid5' | 'grid6'
};


const allProjects: BaseProject[] = [
  {
    name: 'kaspy',
    description: 'application / e-commerce',
    image: Kaspy,
    images: [],
    template: 'grid1',
    capabilities: ['Web design', 'Responsive design'],
    year: 2015,
  },
  {
    name: 'pros',
    description: 'corporate site',
    image: Pros,
    images: [pros1, pros2, pros3, pros4, pros5, pros6],
    template: 'grid1',
    capabilities: ['Web design', 'Responsive design'],
    year: 2015,
  },
  {
    name: 'Kaan Show',
    description: 'entertainment e-commerce site',
    image: Kaan,
    images: [kaan1, kaan2, kaan3, kaan4, kaan5, kaan6],
    template: 'grid1',
    capabilities: ['Web design', 'Responsive design'],
    year: 2015,
  },
  {
    name: 'Toyota Rich',
    description: 'corporate site',
    image: Toyota,
    images: [toyota1, toyota2, toyota3, toyota4, toyota5],
    template: 'grid5',
    capabilities: ['Web design', 'Responsive design'],
    year: 2015,
  },
  {
    name: 'Panjaluck Pasuk',
    description: 'corporate site',
    image: Panjaluck,
    images: [panjaluck1, panjaluck2, panjaluck3, panjaluck4, panjaluck5],
    template: 'grid4',
    capabilities: ['Web design', 'Responsive design'],
    year: 2015,
  },
  {
    name: 'Ramada Plaza Chao Fah',
    description: 'corporate site',
    image: Ramada,
    images: [ramada1, ramada2, ramada3, ramada4, ramada5, ramada6],
    template: 'grid1',
    capabilities: ['Web design', 'Responsive design'],
    year: 2015,
  },
  {
    name: 'Manit Farm',
    description: 'corporate site',
    image: Manit,
    images: [manit1, manit2, manit3, manit4, manit5],
    template: 'grid6',
    capabilities: ['Web design', 'Responsive design'],
    year: 2015,
  },
  {
    name: 'Bodyslam',
    description: 'entertainment site',
    image: Bodyslam,
    images: [bodyslam1, bodyslam2, bodyslam3, bodyslam4, bodyslam5, bodyslam6],
    template: 'grid3',
    capabilities: ['Web design', 'Responsive design'],
    year: 2015,
  },
  {
    name: 'BMMF X',
    description: 'AR Camera FX',
    image: BMMF,
    images: [bmmf1, bmmf2, bmmf3],
    template: 'grid2',
    capabilities: ['AR', 'Camera FX'],
    year: 2019,
  },
];

// add object hash so these are iterable
// add route so we don't get weird %20 spaces
export const projects: Project[] = allProjects.map((p) => ({
  key: hash(p),
  route: p.name.toLowerCase().replace(/\s/g, '-'),
  ...p,
}));
export const aboutUsPullQuote = ``;

export const aboutUs = `
ทีมนักพัฒนาระบบน้องใหม่ไฟแรงของแมดอะไรดี ที่รวบรวมผู้เชี่ยวชาญมากความสามารถในด้านการพัฒนาระบบต่าง ๆ เรารับดูแลตั้งแต่ระบบ Backend, Mobile Application, Website,
Infrastructure, Database, Interactive ที่พร้อมเติมความสมบูรณ์แบบของระบบให้กับงานของคุณ
`;

type Services = { name: string, description: string }

export const services: Services[] = [
  {
    name: 'Mobile Application',
    description:
      'เรารับพัฒนาแอปพลิเคชั่นมือถือบนระบบ iOS และ Android ทั้งในส่วนของ Mobile และ Tablet เพื่อเพิ่มโอกาสให้กับธุรกิจของคุณ ด้วยทีมงานที่พร้อมให้คำปรึกษาและออกแบบระบบได้อย่างมืออาชีพ',
  },
  {
    name: 'Web Design & Development',
    description:
      'เว็บไซต์นับว่าเป็นสิ่งจำเป็นอย่างมากในปัจจุบันสำหรับธุรกิจทุกประเภท เพราะจะแสดงถึงความน่าเชื่อถือของบริษัทและยังสามารถนำเสนอข้อมูลต่างๆได้ 24 ชั่วโมง การออกแบบเว็บไซต์ให้ดูน่าเชื่อถือ แสดงผลได้กับทุกอุปกรณ์แสดงผล รวมถึงการออกแบบระบบให้เข้าใจง่าย พร้อมตอบสนองการใช้งานได้อย่างรวดเร็ว นับเป็นองค์ประกอบสำคัญ ซึ่งเรามุ่งเน้นให้ความสำคัญเพื่อพัฒนาเว็บไซต์ให้กับทุกธุรกิจด้วยทีมงานมืออาชีพเสมอมา',
  },
  {
    name: 'Interactive Touchscreen',
    description:
      'งานออกแบบ Interactive Media นับเป็นจุดสำคัญอีกอย่างในการดึงดูดผู้คนให้สนใจ และเข้าร่วมกิจกรรมของธุรกิจได้เป็นอย่างดี งานออกแบบที่ดี การนำเสนอที่น่าสนใจ ไอเดียแปลกใหม่ คือสิ่งที่เรามีประสบการณ์และชำนาญ พร้อมแนะนำและช่วยส่งต่อโอกาสให้กับคุณ',
  },
  {
    name: 'AR, VR',
    description:
      'เทคโนโลยีโลกเสมือนจริง AR และ VR กำลังเป็นที่จับตามองอย่างมากในปัจจุบัน เพราะจะช่วยส่งเสริมให้กิจกรรมและการนำเสนอต่างๆของคุณดูไม่น่าเบื่ออีกต่อไป เรามีทีมงานที่ชำนาญและเชี่ยวชาญในการออกแบบและพัฒนา รวมถึงเรายังมีไอเดียแปลกๆใหม่ๆที่จะนำเสนอให้คุณ ที่จะทำให้คุณต้องตื่นเต้นไปกับพวกเรา',
  },
  {
    name: 'Game Development',
    description: 'รับออกแบบและพัฒนาเกมทั้ง 2D และ 3D ด้วยทีมงานมืออาชีพ ประสบการณ์มากกว่า 10 ปี ทั้ง Mobile, Tablet, PC, Mac พร้อมทั้งยินดีให้คำปรึกษาในเรื่องการออกแบบ',
  },
];

