import React from 'react';
import { Services } from "./components/Services";
import { ScrollingHeader } from "./components/ScrollingHeader";
import { Projects } from "./components/Projects";
import { AboutUs } from "./components/AboutUs";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className='home'>
      <div className='wrapper'>
        <ScrollingHeader/>

        <Projects />

        <AboutUs />

        <Services/>
      </div>

      <Footer />
    </div>
  );
}

export default App;
