import React, { useEffect, useState } from 'react';
import Ticker from 'react-ticker';
import prosBackground from '../images/hero/Pross-BG.webp';
import prosMac from '../images/hero/Pross-Mac.webp';
import prosPhone from '../images/hero/Pross-Phone.webp';
import prosBackgroundp from '../images/hero/placeholders/Pross-BG.webp';
import prosMacp from '../images/hero/placeholders/Pross-Mac.webp';
import prosPhonep from '../images/hero/placeholders/Pross-Phone.webp';
import kaanBackground from '../images/hero/Kaan-BG.webp';
import kaanMac from '../images/hero/Kaan-Mac.webp';
import kaanBackgroundp from '../images/hero/placeholders/Kaan-BG.webp';
import kaanMacp from '../images/hero/placeholders/Kaan-Mac.webp';
import kaspyBackground from '../images/hero/Kaspy-BG.webp';
import kaspyPhone1 from '../images/hero/Kaspy-Phone1.webp';
import kaspyPhone2 from '../images/hero/Kaspy-Phone2.webp';
import kaspyBackgroundp from '../images/hero/placeholders/Kaspy-BG.webp';
import kaspyPhone1p from '../images/hero/placeholders/Kaspy-Phone1.webp';
import kaspyPhone2p from '../images/hero/placeholders/Kaspy-Phone2.webp';
import BMMFBackground from '../images/hero/BMMF-BG.webp';
import BMMFPhone1 from '../images/hero/BMMF-Phone01.webp';
import BMMFPhone2 from '../images/hero/BMMF-Phone02.webp';
import BMMFPhone3 from '../images/hero/BMMF-Phone03.webp';
import BMMFBackgroundp from '../images/hero/placeholders/BMMF-BG.webp';
import BMMFPhone1p from '../images/hero/placeholders/BMMF-Phone01.webp';
import BMMFPhone2p from '../images/hero/placeholders/BMMF-Phone02.webp';
import BMMFPhone3p from '../images/hero/placeholders/BMMF-Phone03.webp';

export const ScrollingHeader = () => {

  return (
    <div className='hero py-12 md:py-20'>
      <div className='mb-32'>
        <Ticker speed={10} mode='chain'>
          {() => <h2>mad TECHNOLOGY DEVELOPMENT</h2>}
        </Ticker>
      </div>

      <div className='HeroImage'>
        <Splash/>
      </div>
    </div>
  );
}

const splashThemes = ['pros', 'kaan', 'kaspy', 'bmmf']

const Splash = () => {
  const [counter, setCounter] = useState(0)
  const [theme, setTheme] = useState(splashThemes[counter])

  // cycle through all splash headers
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter + 1) % splashThemes.length)
      setTheme(splashThemes[counter])
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [counter]);

  switch (theme) {
    case "pros":
      return <Pros/>
    case "kaan":
      return <Kaan/>
    case "kaspy":
      return <Kaspy/>
    case "bmmf":
      return <BMMF/>
    default: // this case should never happen
      return null
  }
}

const Pros = () => (
  <div className='splash-pros'>
    <div className='background-wrapper min-h-24'>
      <ImageLoad className='relative w-full' src={prosBackground} placeholder={prosBackgroundp}/>
    </div>

    <div className='middleground'>
      <ImageLoad className='animate__animated animate__fadeInUp animate__faster ' src={prosMac} placeholder={prosMacp}/>
    </div>

    <div className='foreground'>
      <ImageLoad className='animate__animated animate__fadeInUp animate__faster animate__delay-05s' src={prosPhone}
                 placeholder={prosPhonep}/>
    </div>
  </div>

)

const Kaan = () => (
  <div className='splash-kaan'>
    <div className='background-wrapper min-h-24'>
      <ImageLoad className='relative w-full' src={kaanBackground} placeholder={kaanBackgroundp}/>
    </div>

    <div className='middleground'>
      <ImageLoad className='animate__animated animate__fadeInUp animate__faster ' src={kaanMac} placeholder={kaanMacp}/>
    </div>
  </div>
)

const Kaspy = () => (
  <div className='splash-kaspy'>
    <div className='background-wrapper min-h-24'>
      <ImageLoad className='relative w-full' src={kaspyBackground} placeholder={kaspyBackgroundp}/>
    </div>

    <div className='middleground'>
      <ImageLoad className='animate__animated animate__fadeInUp animate__faster ' src={kaspyPhone2}
                 placeholder={kaspyPhone2p}/>
    </div>

    <div className='foreground'>
      <ImageLoad className='animate__animated animate__fadeInUp animate__faster animate__delay-05s' src={kaspyPhone1}
                 placeholder={kaspyPhone1p}/>
    </div>
  </div>
)

const BMMF = () => (
  <div className='splash-bmmf'>
    <div className='background-wrapper min-h-24'>
      <ImageLoad className='relative w-full' src={BMMFBackground} placeholder={BMMFBackgroundp}/>
    </div>

    <div className='first'>
      <ImageLoad className='animate__animated animate__fadeInUp animate__faster' src={BMMFPhone1}
                 placeholder={BMMFPhone1p}/>
    </div>

    <div className='second'>
      <ImageLoad className='animate__animated animate__fadeInUp animate__faster animate__delay-05s' src={BMMFPhone2}
                 placeholder={BMMFPhone2p}/>
    </div>

    <div className='third'>
      <ImageLoad className='animate__animated animate__fadeInUp animate__faster animate__delay-1s' src={BMMFPhone3}
                 placeholder={BMMFPhone3p}/>
    </div>
  </div>
)

// lazyload images
type ImageLoadProps = { placeholder: string, src: string, alt?: string, className: string }
const ImageLoad: React.FC<ImageLoadProps> = React.memo(({ placeholder, src, alt = '', className }) => {
  const [loading, setLoading] = useState(true);
  const [currentSrc, updateSrc] = useState(placeholder);

  useEffect(() => {
    // start loading original image
    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      // When image is loaded replace the src and set loading to false
      setLoading(false);
      updateSrc(src);
    }
  }, [src])

  return (
    <img className={className} src={currentSrc} style={{
      opacity: loading ? 0.5 : 1,
      width: loading ? "100%" : "unset",
      filter: loading ? 'blur(11px)' : "none",
      transition: "opacity .15s linear"
    }} alt={alt}/>
  )
});
