import React, { useState } from 'react';
import { services } from "../data";

export function Services() {
  const [activeService, setActiveService] = useState('Technology Consulting');

  return (
    <div className='services grid'>
      <h2>Services</h2>
      <div className='category'>
        {services.map(x => x.name).map((name, index) => (
          <h3 key={index} className={`cursor-pointer ${name === activeService ? 'active' : ''}`}
              onClick={() => setActiveService(name)}>
            {name}
          </h3>
        ))}
      </div>
      <div className='text'>
        {services.map((s, index) => (
          <p key={index} className={
            s.name !== activeService
              ? 'animate__animated animate__fadeOut animate__faster hidden'
              : 'animate__animated animate__fadeIn animate__faster'
          }>
            {s.description}
          </p>
        ))}
      </div>
    </div>
  );
}
