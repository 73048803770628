import { aboutUs, aboutUsPullQuote } from "../data";
import React from "react";

export function AboutUs() {
  return (
    <div className='about-us'>
      <h2>About Us</h2>
      <p>
        {aboutUs}
      </p>

      <blockquote>
        {aboutUsPullQuote}
      </blockquote>
    </div>
  )
}