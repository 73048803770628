import React, { useState } from 'react';
import { projects, Project } from '../data';
// import close from '../images/close.png'
import ReactModal from 'react-modal';

export function Projects() {
  return (
    <div className='projects'>
      {projects.map((p) => (<ProjectThumbnail {...p} />))}
    </div>
  );
}

function ProjectThumbnail({ name, description, image, images, template, capabilities, year, route }: Project) {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {/* The thumbnail */}
      <div id={route} className='project-item mb-4' onClick={openModal}>
        <img className='mb-2 cursor-pointer' src={image} alt=''/>
        <h3 className='text-3xl uppercase leading-6'>{name}</h3>
        <p className='text-2xl lowercase leading-6'>{description}</p>
      </div>

      {/* The modal that appears after clicking on the thumbnail */}
      <ReactModal isOpen={modalIsOpen} shouldCloseOnOverlayClick={true} shouldCloseOnEsc={true}
                  shouldReturnFocusAfterClose={true} style={{}} className='modal-wrapper'
                  overlayClassName='modal-overlay' onRequestClose={closeModal} closeTimeoutMS={500}>

        {/* wrapper to position modal-btn */}
        <div className='modal'>

          {/* the content */}
          <div className={`content ${template}`}>

            <div className='content-item-container'>
              <img className='content-item' alt='' src={images[0]}/>
            </div>
            <div className='content-item-container'>
              <img className='content-item' alt='' src={images[1]}/>
            </div>
            <div className='content-item-container'>
              <img className='content-item' alt='' src={images[2]}/>
            </div>

            {/* Three images are guaranteed, the others might not exist depending on the template */}
            {!images[3] ? null :
              <div className='content-item-container'>
                <img className='content-item' alt='' src={images[3]}/>
              </div>}
            {!images[4] ? null :
              <div className='content-item-container'>
                <img className='content-item' alt='' src={images[4]}/>
              </div>}
            {!images[5] ? null :
              <div className='content-item-container'>
                <img className='content-item' alt='' src={images[5]}/>
              </div>}
          </div>

          {/* the footer */}
          <div className='footer'>
            <div className='info'>
              <h3 className='text-4xl uppercase leading-10'>{name}</h3>
              <p className='text-3xl lowercase leading-6'>{description}</p>
              <p className='text-lg lowercase leading-6'>{year}</p>
            </div>
            <div className='meta'>
              <p className='text-lg lowercase leading-6'>we do</p>
              {!capabilities ? null : (
                <ul className=''>
                  {capabilities.map((c) => (
                    <li key={c} className='text-3xl leading-none'>+ {c}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        {/* The close button */}
        <button className='modal-btn' onClick={closeModal}>
          <svg xmlns='http://www.w3.org/2000/svg' width='36.078' height='36.078'>
            <path fill='#fff'
                  d='M18.039 13.796L4.242 0 0 4.244l13.796 13.795L0 31.834l4.242 4.243 13.797-13.795 13.797 13.796 4.242-4.244-13.796-13.795L36.078 4.244 31.836.001z'/>
          </svg>
        </button>

      </ReactModal>
    </>
  );
}
